import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { CommonModule } from '@angular/common';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AmplifyUIAngularModule } from '@aws-amplify/ui-angular';
import { ManagePage } from './manage/manage.page';
import { SuccessPage } from './success/success.page';
import { FormsModule } from '@angular/forms';
import { ConsentPage } from './consent/consent.page';
import { SubscribePage } from './subscribe/subscribe.page';
import { ManageInAppPage } from './manage-in-app/manage-in-app.page';
import { CancelSubscriptionModalComponent } from './manage/cancel-subscription-modal/cancel-subscription-modal.component';

@NgModule({
  declarations: [
    AppComponent,
    ManagePage,
    SubscribePage,
    SuccessPage,
    ConsentPage,
    ManageInAppPage,
    CancelSubscriptionModalComponent,
  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    CommonModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    AmplifyUIAngularModule,
    FormsModule,
  ],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
  bootstrap: [AppComponent],
})
export class AppModule {}
