/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { API, Auth } from 'aws-amplify';
import {
  AlertController,
  LoadingController,
  ModalController,
  ToastController,
} from '@ionic/angular';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { AppService } from '../app.service';
import { CasenoterProductWithPrice } from '../app.types';
import { STRIPE_API_NAME } from '../app.constants';
import { CancelSubscriptionModalComponent } from './cancel-subscription-modal/cancel-subscription-modal.component';

interface CasenoterSubscriptionData /*extends CasenoterSubscriptionResponse*/ {
  // currency: string;
  product: any;
  name: string;
  // price: string;
  expiryDate: string;
  currentPeriodEnd: number;
  canRenew: boolean;
  // amount: number;
}

interface CasenoterSubscriptionResponse {
  success: boolean;
  subscriptionData: CasenoterSubscriptionData;
}

@Component({
  selector: 'app-manage-page',
  templateUrl: './manage.page.html',
  styleUrls: ['./manage.page.scss'],
})
export class ManagePage implements OnInit {
  chargeData: any;
  customerData: any;
  activeSubscription: CasenoterSubscriptionData;
  allProducts: CasenoterProductWithPrice[];
  customerEmail: string;
  customerId: string;
  deletedSubscription = false;
  isLive = true;

  /**
   * Number 0-12, indicating ion-col width of the labels column
   */
  readonly LABEL_COLUMN_WIDTH = 3;

  /**
   * Number 0-12, indicating ion-col width of the entire page
   */
  readonly TOTAL_COLUMN_WIDTH = 10;

  readonly REMAINING_WIDTH = this.TOTAL_COLUMN_WIDTH - this.LABEL_COLUMN_WIDTH;

  constructor(
    private router: Router,
    private alertController: AlertController,
    private loadingController: LoadingController,
    private toastController: ToastController,
    private modalController: ModalController,
    private appService: AppService
  ) {}

  get subscriptionIsExpired() {
    return this.activeSubscription?.currentPeriodEnd < new Date().valueOf();
  }

  async ngOnInit() {
    const loading = await this.loadingController.create({
      message: 'Please wait...',
    });
    await loading.present();
    let user: CognitoUser;
    try {
      user = await Auth.currentAuthenticatedUser();
    } catch (err) {
      loading.dismiss();
      const alert = await this.alertController.create({
        message: 'You have been signed out. Please sign in again to continue.',
        header: 'Not signed in',
        buttons: ['OK'],
      });
      await alert.present();
      await alert.onDidDismiss().then(() => {
        this.router.navigateByUrl('/home');
      });
    }
    try {
      const attributes = await Auth.userAttributes(user);
      this.customerEmail = attributes.find((e) => e.Name === 'email').Value;
      this.customerId = attributes.find(
        (e) => e.Name === 'custom:stripe_id'
      )?.Value;
      const offerList = await this.getCasenoterOfferProducts();
      this.isLive = offerList.isLive;
      if (offerList.success) {
        this.allProducts = offerList.data;
        // offerList.productData.forEach((product) => (product.prices = []));
        // this.allProducts = offerList.productData;
        // const allPrices: StripePriceObject[] = offerList.priceData;
        // allPrices.forEach((price) =>
        //   this.allProducts
        //     .find((product) => product.id === price.product)
        //     .prices.push({
        //       id: price.id,
        //       amount:
        //         price.currency.toUpperCase() +
        //         '$' +
        //         this.userService.toDollarString(price.unit_amount ?? 0),
        //     }),
        // );
      } else {
        console.error('Could not get product data');
      }
      const stripeId = attributes.find(
        (e) => e.Name === 'custom:stripe_id'
      )?.Value;
      await this.getStripeCustomerSubData(stripeId)
        .then((res) => {
          if (res.success) {
            //this.chargeData = res.chargeData.data[0];
            // this.customerData = res.customerData;
            // Data for page
            this.activeSubscription = {
              currentPeriodEnd: res.subscriptionData.currentPeriodEnd * 1000,
              expiryDate: new Date(
                res.subscriptionData.currentPeriodEnd * 1000
              ).toDateString(),
              name: res.subscriptionData.product.name,
              product: res.subscriptionData.product.id,
              canRenew: res.subscriptionData.canRenew,
            };
            // if (this.activeSubscription) {
            //   // this.activeSubscription.name = this.allProducts.find(
            //   //   (product) => product.id === this.activeSubscription.product,
            //   // ).name;
            //   // this.activeSubscription.expiryDate = new Date(
            //   //   this.activeSubscription.currentPeriodEnd * 1000,
            //   // ).toString();
            //   // this.activeSubscription.price =
            //   //   this.activeSubscription.currency.toUpperCase() +
            //   //   '$' +
            //   //   this.userService.toDollarString(this.activeSubscription.amount);
            // }
          } else {
            throw Error(
              'getStripeCustomerSubData success false: ' + JSON.stringify(res)
            );
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } finally {
      loading.dismiss();
    }
  }

  async getStripeCustomerData(stripeId: string) {
    const items = await this.appService.stripeAPICall(
      'GET',
      `/customer/${stripeId}`,
      {}
    );
    return items;
  }

  async getStripeCustomerSubData(
    stripeId: string
  ): Promise<CasenoterSubscriptionResponse> {
    const items = await this.appService.stripeAPICall(
      'GET',
      `/subscriptions/customer/${stripeId}`
    );
    return items;
  }

  /**
   * Selects products defined in the Stripe environment where:
   *
   * * metadata.ITERATIONS is defined and more than 0;
   *
   * * metadata.CASENOTER matches the format `OPTION[ ]{number}`.
   *    - Example: OPTION1, OPTION2, OPTION3, OPTION 1, OPTION 4, OPTION 6, OPTION100 are all valid.
   *    - OPTIONONE, OPTION, OPTION ONE, OPT1, 1, etc. are all invalid and will not be selected
   *      by this API.
   *    - Case insensitive, so `option1` is also valid.
   *
   *   Uses regex: /OPTION(\s)*(\d+)$/i
   */
  async getCasenoterOfferProducts(): Promise<{
    success: boolean;
    data: CasenoterProductWithPrice[];
    isLive: boolean;
  }> {
    return await this.appService.stripeAPICall('GET', 'prices/offers');
  }

  // async confirmCancel(subId: string) {
  //   const alert = await this.alertController.create({
  //     message: 'Are you sure you want to cancel your subscription?',
  //     buttons: [
  //       'CANCEL',
  //       { text: 'CONFIRM', handler: () => this.cancelSubscription(subId) },
  //     ],
  //     header: 'Confirm cancel subscription',
  //   });
  //   await alert.present();
  // }

  async renewSubscription() {
    this.alertController
      .create({
        message:
          'Confirm whether you want to continue your subscription.',
        header: 'Confirm',
        buttons: [
          'Cancel',
          {
            text: 'Confirm',
            handler: async () => {
              await this.appService
                .stripeAPICall('POST', '/subscriptions/customer/renew')
                .then((result) => {
                  if (result.success) {
                    this.toastController
                      .create({
                        message:
                          'Your subscription has been renewed successfully.',
                        duration: 5000,
                        color: 'success',
                      })
                      .then((toast) => {
                        toast.present();
                      });
                  } else {
                    throw Error(
                      'Could not renew subscription - API success is false.'
                    );
                  }
                })
                .catch((error) => {
                  console.error(error);
                  this.toastController
                    .create({
                      header: 'An error occurred.',
                      message:
                        'Sorry, an error prevented your subscription from renewing. Error: ' +
                        error.message,
                      duration: 10000,
                      color: 'danger',
                    })
                    .then((toast) => {
                      toast.present();
                    });
                });
            },
          },
        ],
      })
      .then((alert) => {
        alert.present();
      });
  }

  presentConfirmCancelSubModal() {
    this.modalController
      .create({ component: CancelSubscriptionModalComponent, cssClass: 'cancel-subscription-modal' })
      .then((modal) => {
        modal.present();
      });
  }

  // async cancelSubscription(subId?: string) {
  //   // if (!subId) subId = this.activeSubscription.id;
  //   const sub = await this.appService.stripeAPICall(
  //     'del',
  //     `subscriptions/${subId}`,
  //     {}
  //   );
  //   if (sub?.success) {
  //     this.deletedSubscription = true;
  //   }
  //   this.activeSubscription = null;
  //   return sub;
  // }

  signOut() {
    Auth.signOut();
  }

  private stringifyParams(params: any): string {
    return Object.keys(params ?? {}).reduce(
      (previousValue: string, currentValue: string) =>
        previousValue?.length
          ? `${previousValue}&${currentValue}=${params[currentValue]}`
          : `?${currentValue}=${params[currentValue]}`,
      ''
    );
  }
}
