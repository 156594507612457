/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit } from '@angular/core';
import { AlertController, LoadingController } from '@ionic/angular';
import { AuthState } from '@aws-amplify/ui-components';
import { Auth } from 'aws-amplify';
import { Router } from '@angular/router';
import { AppService } from '../app.service';
import { getDisplayErrorMessage } from '../helpers';

@Component({
  selector: 'app-manage-in-app',
  templateUrl: './manage-in-app.page.html',
  styleUrls: ['./manage-in-app.page.scss'],
})
export class ManageInAppPage implements OnInit {
  email =
    this.appService.paymentIntegratedEmail ??
    this.router.routerState.snapshot.root.queryParamMap.get(
      'paymentIntegratedEmail'
    );
  password: string;
  authState: AuthState;

  loading: HTMLIonLoadingElement;
  error: string;

  constructor(
    private alertController: AlertController,
    private loadingController: LoadingController,
    private router: Router,
    public appService: AppService
  ) {
    const priceOptionArgument = parseInt(
      this.router.routerState.root.snapshot.queryParamMap.get('priceOption'),
      10
    );
    if (!isNaN(priceOptionArgument)) {
      this.appService.priceOption = priceOptionArgument;
    }
  }

  async ngOnInit() {
    if (!this.email) {
      this.router.navigateByUrl('/consent');
    }
  }

  async signIn() {
    if (!this.password?.length) {
      this.error = 'You must enter a password to proceed.';
      return;
    }
    this.loading = await this.loadingController.create({
      mode: 'md',
      message: 'Verifying password...',
    });
    this.loading.present();
    await Auth.signIn(this.email, this.password)
      .then(() => {
        this.router.navigate(['/subscribe'], { queryParams: {} });
      })
      .catch((error) => {
        console.error('Error in verifying password:', error);
        const mappedError = getDisplayErrorMessage(error);
        this.error = mappedError ?? 'The password is incorrect.';
      })
      .finally(() => {
        this.password = '';
        this.loading.dismiss();
      });
  }

  returnToApp() {
    window.history.back();
  }

  private async shortAlert(
    message: string,
    header?: string,
    buttons?: any[] | string[]
  ) {
    const alert = await this.alertController.create({
      message,
      header,
      buttons: buttons ? buttons : ['OK'],
    });
    await alert.present();
    return alert;
  }
}
