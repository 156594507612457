/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import * as Stripe from '@stripe/stripe-js';
import { Auth } from 'aws-amplify';
import { AlertController, LoadingController } from '@ionic/angular';
import { AppService } from '../app.service';
import { getCurrencySymbol } from '@angular/common';
import { RequestError, StripeAddress } from '../app.types';

interface ProductInformation {
  /** Example: `product_xxxxxxxxxxx` */
  id: string;
  /** Name determined by the Stripe dashboard product configuration. */
  name: string;
  /** Description determined by the Stripe dashboard product configuration. */
  description?: string;
  /** Indicates whether the Stripe object is in production. */
  isLive: boolean;
}

@Component({
  templateUrl: './subscribe.page.html',
  styleUrls: ['./subscribe.page.scss'],
})
export class SubscribePage implements OnInit {
  @ViewChild('couponInput') couponInput: HTMLIonInputElement;

  /**
   * The card name element, the non-Stripe-secured element on the form page.
   * Its value is stored in the billing details of the payment method.
   */
  @ViewChild('cardNameElement') cardNameElement: HTMLIonInputElement;

  @ViewChild('permissionCheckbox') permissionCheckbox: HTMLIonCheckboxElement;

  get productInformation() {
    return this.appService.productInformation;
  }

  get priceInformation() {
    return this.appService.priceInformation;
  }

  get isLive() {
    return this.appService.isLive;
  }

  constructor(
    private router: Router,
    private alertController: AlertController,
    private loadingController: LoadingController,
    private appService: AppService
  ) {}

  /**
   * Indicates an error that occurred when attempting to fetch
   * information about a coupon.
   */
  couponError: string;

  /**
   * The Stripe Elements instance which is used to instantiate and extract
   * values from secured form elements.
   */
  elements: Stripe.StripeElements;

  /**
   * If `true`, the user will be presented with an additional address form,
   * and it will be required to proceed to payment.
   */
  includeAddress = false;

  /**
   * The address form values that will be sent to the payment API if
   * `includeAddress` is true.
   */
  addressForm = {
    line1: '',
    line2: '',
    country: 'Australia',
    state: '',
    city: '',
    postal: '',
  };

  // https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2#Officially_assigned_code_elements
  readonly COUNTRY_MAPPINGS = {
    Australia: 'AU',
    USA: 'US',
  };

  /**
   * If the user selected a price from the Casenoter pricing page
   * (https://casenoter.com/pricing), this will be the value of the
   * option, to indicate which product the user will be subscribing to.
   */
  priceOption = this.appService.priceOption;

  /**
   * @deprecated The currently unused price amount string of the previous
   * implementation before a new string was added that accounts for the
   * discounted coupon amount.
   */
  priceAmount: string;

  /**
   * If `true`, the customer may pay with the third-party payment request
   * API through Stripe, enabling technology like GooglePay and Apple Pay.
   * Should only be possible in production.
   */
  paymentRequestAvailable = false;

  /**
   * The Ionic LoadingElement component unique to this page.
   */
  loading: HTMLIonLoadingElement;

  /**
   * The error message, if any, to display at the bottom of the page which
   * indicates any issues with proceeding with payment.
   */
  errorDisplay: string;

  /**
   * Boolean model value indicating whether or not the user has agreed to
   * comply with the Terms of Servie.
   */
  customerPermission = false;

  /**
   * If `true`, the user has filled the form satisfactorily and can
   * proceed with payment with the given information.
   */
  formReady = false;

  /**
   * An object containing the completed status individual fields of the
   * form that must be completed before proceeding.
   */
  formReadyFields: {
    card: boolean;
    name: boolean;
    cvc: boolean;
    expiry: boolean;
  } = {
    card: false,
    name: false,
    cvc: false,
    expiry: false,
  };

  /**
   * If the user is managing their payments/subscriptions via the app,
   * this value will be set to their email.
   */
  get paymentIntegratedEmail() {
    return this.appService.paymentIntegratedEmail;
  }

  get stripe() {
    return this.appService.getStripe();
  }

  /** Displays a price with a customer-friendly appearance. */
  get displayAmount() {
    return this.appService.priceInformation.unitAmount === 0
      ? 'Free'
      : `${getCurrencySymbol(
          this.appService.priceInformation.currency.toUpperCase(),
          'wide'
        )} ${((this.appService.priceInformation.unitAmount || 0) / 100).toFixed(
          2
        )}`;
  }

  /**
   * A string to display to the user that represents how much they would
   * pay after the coupon discount.
   */
  get couponDisplayAmount() {
    return `${getCurrencySymbol(
      this.appService.priceInformation.currency,
      'wide'
    )} ${(this.appService.priceInformation.couponUnitAmount / 100).toFixed(
      2
    )} (discounted by ${this.appService.priceInformation.discount})`;
  }

  async ngOnInit() {
    this.loading = await this.loadingController.create({
      mode: 'md',
      message: 'Loading payment form...',
    });
    await this.loading.present();
    try {
      const stripe = await this.stripe;
      this.elements = stripe.elements();
      const styleTheme: Stripe.StripeElementStyle = {
        base: {
          fontFamily: '"Lucida Console" "sans-serif" "arial"',
        },
      };

      // Initiate price selections if not already
      if (!this.appService.priceInformation) {
        await this.appService.initSelectedPrice(true);
      }

      // Only enable this in live mode because otherwise it may
      // charge the full amount in dev or test environments.
      if (
        !this.paymentIntegratedEmail &&
        this.isLive &&
        this.appService.priceInformation.unitAmount > 0
      ) {
        const paymentRequest = stripe.paymentRequest({
          /**
           * Allowed countries:
           * AE, AT, AU, BE, BG, BR, CA, CH, CI, CR, CY, CZ, DE, DK, DO,
           * EE, ES, FI, FR, GB, GI, GR, GT, HK, HU, ID, IE, IN, IT, JP,
           * LI, LT, LU, LV, MT, MX, MY, NL, NO, NZ, PE, PH, PL, PT, RO,
           * SE, SG, SI, SK, SN, TH, TT, US, UY
           */
          country: 'AU',
          currency: 'aud',
          total: {
            label: 'Total price',
            amount: this.appService.priceInformation.unitAmount,
          },
          requestPayerName: false,
          requestPayerEmail: false,
          requestPayerPhone: false,
        });
        this.paymentRequestAvailable =
          !!(await paymentRequest.canMakePayment());
        if (this.paymentRequestAvailable) {
          const elementPayment = this.elements.create('paymentRequestButton', {
            paymentRequest,
          });
          elementPayment.mount('#payment-request-button');
        }
      }
      const elementCardNumber = this.elements.create('cardNumber', {
        style: styleTheme,
        showIcon: true,
      });
      const elementCardExpiry = this.elements.create('cardExpiry', {
        style: styleTheme,
      });
      const elementCVC = this.elements.create('cardCvc', {
        style: styleTheme,
        placeholder: '123',
      });
      elementCardNumber.on('change', (event) => {
        this.formReadyFields.card = event.complete;
        this.verifyForm();
      });
      elementCardExpiry.on('change', (event) => {
        this.formReadyFields.expiry = event.complete;
        this.verifyForm();
      });
      elementCVC.on('change', (event) => {
        this.formReadyFields.cvc = event.complete;
        this.verifyForm();
      });
      elementCardNumber.mount('#card-number-element');
      elementCardExpiry.mount('#card-expiry-element');
      elementCVC.mount('#card-cvc-element');
    } finally {
      this.loading.dismiss();
    }
  }

  async processPayment() {
    this.errorDisplay = null;
    this.loading = await this.loadingController.create({
      mode: 'md',
      message: 'Please wait while your payment processes...',
    });
    await this.loading.present();
    const attributes = await Auth.userAttributes(this.appService.user);
    const userEmail = attributes.find(
      (attribute) => attribute.Name === 'email'
    ).Value;
    try {
      if (!this.addressForm.line1 && this.includeAddress) {
        this.errorDisplay = 'Address Line 1 is required.';
        return;
      }

      const address = this.includeAddress
        ? ({
            line1: this.addressForm.line1,
            line2: this.addressForm.line2,
            country:
              this.COUNTRY_MAPPINGS[this.addressForm.country] ??
              this.addressForm.country,
            state: this.addressForm.state,
            city: this.addressForm.city,
            postal_code: this.addressForm.postal,
          } as StripeAddress)
        : null;

      // Phone number is disabled indefinitely.
      // const phoneNumber = attributes.find(
      //   (attribute) => attribute.Name === 'phone_number',
      // ).Value;

      const paymentMethodResult = await this.createPaymentMethod(
        userEmail,
        address
      );

      const paymentResult = await this.appService
        .stripeAPICall('POST', 'payment', {
          userInformation: {
            // phone: phoneNumber,
            address,
          },
          paymentMethodId: paymentMethodResult.paymentMethod.id,
          productId:
            this.appService.chosenProductId ??
            this.appService.productInformation.id,
          couponCode: this.couponInput?.value,
        })
        .catch((error: RequestError) => {
          // console.log('error key values:', {
          //   config: error.config,
          //   request: error.request,
          //   response: error.response,
          //   isAxiosError: error.isAxiosError,
          //   toJSON: error.toJSON(),
          // });
          // this.errorDisplay = error.response.data.error;
          throw Error(error.response.data.error);
        });

      this.router.navigate(['/success'], {
        queryParams: { subId: paymentResult.subId },
      });
      return;
    } catch (err) {
      console.error('ERROR:', err);
      this.errorDisplay = /Request failed/i.test(err.message)
        ? 'Sorry, something went wrong. Please try again later.'
        : err.message;
    } finally {
      this.loading.dismiss();
    }
  }

  async createPaymentMethod(email: string, address?: StripeAddress) {
    return await this.stripe
      .then((stripe) =>
        stripe.createPaymentMethod({
          card: this.elements.getElement('cardNumber'),
          billing_details: {
            name: this.cardNameElement.value,
            email,
            // phone: phoneNumber,
            address,
          },
          type: 'card',
        } as Stripe.CreatePaymentMethodData)
      )
      .catch((error) => {
        throw Error(error.message);
      });
  }

  permissionChange() {
    this.customerPermission = !this.customerPermission;
    this.permissionCheckbox.checked = this.customerPermission;
    this.verifyForm();
  }

  nameChange() {
    this.formReadyFields.name = !!this.cardNameElement.value;
    this.verifyForm();
  }

  verifyForm() {
    this.formReady =
      this.formReadyFields.card &&
      this.formReadyFields.expiry &&
      this.formReadyFields.cvc &&
      this.formReadyFields.name &&
      this.customerPermission;
  }

  resetCouponErrorState(event: any) {
    this.couponError = null;
  }

  async getCouponInfo(value: any) {
    console.log('getCouponInfo event:', value);
    if (value?.length) {
      return this.appService.getCouponInfo(value);
    }
  }
}
