import { Component } from '@angular/core';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(private appService: AppService) {}

  get integratedInApp() {
    return !!this.appService.paymentIntegratedEmail?.length;
  }
}
