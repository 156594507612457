import { Component } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';
import { API } from 'aws-amplify';
import { STRIPE_API_NAME } from 'src/app/app.constants';

@Component({
  selector: 'app-cancel-subscription-modal',
  template: `
    <div class="modal-wrapper">
      <div
        *ngIf="successfullyCancelled; else notCancelled"
        class="cancelled-section-wrapper"
      >
        <div class="close-icon-wrapper top-right" (click)="dismiss(false)">
          <ion-icon name="close-outline" class="close-icon"></ion-icon>
        </div>
        <div class="cancelled-body-section">
          <div class="confirm-icon-wrapper">
            <img src="assets/cancel-confirm-check.svg" class="confirm-check-icon" />
          </div>
          <p class="confirm-cancel-text">
            Successfully cancelled your subscription!
          </p>
        </div>
      </div>
      <ng-template #notCancelled>
        <div class="header-section">
          <h1 class="header-text">Cancel Subscription</h1>
          <div class="close-icon-wrapper" (click)="dismiss(false)">
            <ion-icon name="close-outline" class="close-icon"></ion-icon>
          </div>
        </div>
        <div class="body-section">
          <p class="body-text">
            You&apos;re wishing to cancel subscription. Are you sure?
          </p>
        </div>
        <div class="footer-section">
          <ion-button
            class="action-button"
            color="secondary"
            shape="round"
            fill="outline"
            (click)="cancelSubscription()"
            >Yes, Cancel!</ion-button
          >
          <ion-button
            class="action-button"
            color="secondary"
            shape="round"
            (click)="dismiss(false)"
            >No, Keep it.</ion-button
          >
        </div>
      </ng-template>
    </div>
  `,
  styleUrls: ['./cancel-subscription-modal.component.scss'],
})
export class CancelSubscriptionModalComponent {
  successfullyCancelled = false;

  constructor(
    private modalController: ModalController,
    private loadingController: LoadingController
  ) {}

  dismiss(confirm = false) {
    this.modalController.dismiss({ confirmed: confirm });
  }

  async cancelSubscription() {
    const loadController = await this.loadingController
      .create({ message: 'Cancelling your subscription...' })
      .then((loader) => {
        loader.present();
        return loader;
      });
    await API.del(STRIPE_API_NAME, '/customer/subscriptions', {})
      .then(() => {
        this.successfullyCancelled = true;
      })
      .finally(() => {
        loadController.dismiss();
      });
  }
}
