import { Injectable } from '@angular/core';
import { API, Auth, graphqlOperation } from 'aws-amplify';
import { STRIPE_API_NAME } from './app.constants';
import {
  GetCouponResult,
  GetPriceInfoResult,
  GuestUserStatus,
  StripePriceInfo,
  StripeProductInfo,
} from './app.types';
import { AlertController } from '@ionic/angular';
import * as Stripe from '@stripe/stripe-js';
import { Router } from '@angular/router';
import { CognitoUser } from '@aws-amplify/auth';

type GetGuestPriceInfoResult = Omit<GetPriceInfoResult, 'description'> & {
  warning?: string;
};

@Injectable({
  providedIn: 'root',
})
export class AppService {
  // initially set to true to skip consent form on registration
  agreed = true;
  guestEmail: string;
  signInWith: string;
  priceOption: number;
  guestStatus: GuestUserStatus;
  paymentIntegratedEmail: string;

  /**
   * Information of the Stripe product that the user is subscribing to.
   */
  productInformation: StripeProductInfo;

  /**
   * Information of the price that the user is about to pay/subscribe for.
   */
  priceInformation: StripePriceInfo & {
    couponUnitAmount?: number;
    discount?: string;
  };

  /**
   * If `true`, this indicates that the user is currently viewing the
   * production environment version of the page. If `false`, the user
   * sees a banner indicating that no payments will be made.
   */
  isLive = true;

  chosenProductId: string =
    this.router.routerState.snapshot.root.queryParamMap.get('productId');

  /**
   * The currently authenticated Cognito user.
   */
  user: {
    Session: any;
    attributes: {
      email: string;
      email_verified: boolean;
      family_name: string;
      given_name: string;
      sub: string;
      'custom:guest': 'true' | 'false';
    };
    username: string;
  };

  stripeInstance: Stripe.Stripe;

  /**
   * If `true`, the price the user must pay will attempt to be found
   * automatically, otherwise will display an error to the user if no
   * price is explicitly selected.
   */
  readonly AUTOFIND_PRICE = true;

  get version() {
    return '1.1.0';
  }

  constructor(
    private alertController: AlertController,
    private router: Router
  ) {}

  public toDollarString(cents: number): string {
    if (!cents) {
      return 'Free';
    }
    const str = cents.toString();
    if (str.length === 1) {
      return '0.0' + str;
    }
    if (str.length === 2) {
      return '0.' + str;
    }
    return (
      str.substring(0, str.length - 2) + '.' + str.substring(str.length - 2)
    );
  }

  async getStripe() {
    if (!this.stripeInstance) {
      const publishableKey = await this.stripeAPICall(
        'GET',
        'publishableKeys'
      ).then((result) => result.publishableKey);

      this.stripeInstance = await Stripe.loadStripe(publishableKey);
    }
    return this.stripeInstance;
  }

  /**
   * Creates a simple alert box with one "OK" button for displaying a simple message to the user.
   *
   * @param message The message to be displayed in the body of the alert.
   * @param header (optional) The message to be displayed as a header on the alert.
   * @returns The Ionic Loading element once it is presented.
   */
  async shortAlert(message: string, header?: string) {
    const alert = await this.alertController.create({
      message,
      header,
      buttons: ['OK'],
    });
    await alert.present();
    return alert;
  }

  /**
   * Will return a 401 error if using the dev environment credentials.
   *
   * @returns
   *   0: User does not exist
   *
   *   1: User is registered
   *
   *   2: User is not registered but has been nominated
   *
   *   3: User has not registered, and their nomination was deleted
   */
  async verifyGuestStatus(
    targetEmail?: string
  ): Promise<GuestUserStatus | null> {
    const statement = `query VerifyGuestStatus($targetEmail: String) {
        verifyGuestStatus(targetEmail: $targetEmail)
      }`;
    const response = (await API.graphql(
      graphqlOperation(statement, { targetEmail })
    )) as { data: { verifyGuestStatus: GuestUserStatus } };
    return response.data.verifyGuestStatus as GuestUserStatus;
  }

  /**
   * Performs the necessary actions for the user based upon what they
   * could have selected for the Stripe product. Will direct the user
   * to the subscribe page, where they enter their card, or perform
   * the subscription automatically if the user is inferred to have
   * selected a free product.
   */
  async initSelectedPrice(redirect = true) {
    console.log('initSelectedPrice:', redirect);
    this.user = await Auth.currentAuthenticatedUser();

    const userAttributes = await Auth.userAttributes(this.user);

    /**
     * If `true`, the guest price will be loaded. Determined by the currently
     * logged in user's custom:guest and custom:made_subscription Cognito
     * attributes.
     */
    const loadGuestPrice =
      userAttributes.find((attribute) => attribute.Name === 'custom:guest')
        ?.Value === 'true' &&
      userAttributes.find(
        (attribute) => attribute.Name === 'custom:made_subscription'
      )?.Value !== 'true';

    const nonGuestPriceErrorHandler = (error: Error) => {
      console.warn('error:', error);
      this.shortAlert(
        `Sorry, an error occurred.
    You will be redirected to the account management page, where you will be offered to select from existing subscriptions.`
      ).then((alert) => {
        alert.onWillDismiss().then(() => {
          this.router.navigate(['/manage']);
        });
      });
    };

    try {
      if (!this.user) {
        const alert = await this.alertController.create({
          mode: 'md',
          message:
            'You are not signed in. The session may have expired, or you may need to enable Cookies in your browser.',
          header: 'Signed out',
          buttons: ['OK'],
        });
        await alert.present();
        alert.onDidDismiss().then(() => {
          this.router.navigateByUrl('/home');
        });
        return;
      }

      if (loadGuestPrice) {
        await this.getGuestPriceInformation().catch((error) => {
          this.alertController
            .create({
              header: 'Something went wrong',
              message: `Error details: ${error.message}`,
            })
            .then((alert) => {
              alert.present();
            });
        });
      } else if (this.chosenProductId) {
        await this.getProductInformation(this.chosenProductId)
          .then((result) => {
            this.priceInformation = result.priceData;
            this.productInformation = result.productData;
            // this.priceAmount =
            //   this.priceInformation.currency.toUpperCase() +
            //   '$' +
            //   this.toDollarString(this.priceInformation.unitAmount);
            this.isLive = result.isLive;
          })
          .catch(nonGuestPriceErrorHandler);
      } else if (this.priceOption) {
        console.log('price option');
        await this.getPriceOptionInformation(this.priceOption).catch(
          nonGuestPriceErrorHandler
        );
      } else if (this.AUTOFIND_PRICE) {
        console.log('getting default');
        await this.getDefaultPriceInformation().catch(
          nonGuestPriceErrorHandler
        );
      } else {
        this.alertController
          .create({
            message: 'You must select a product to subscribe to.',
            buttons: ['OK'],
          })
          .then((alert) => {
            alert.present();
          });
      }
      if (redirect) {
        if (this.guestEmail?.length) {
          this.router.navigateByUrl('/home');
        }
        const userSubscribingFree =
          this.priceInformation.isFree || this.productInformation.isFree;
        if (userSubscribingFree) {
          console.log('userSubscribingFree:', {
            user: this.user,
            price: this.priceInformation,
            product: this.productInformation,
            chosenProductId: this.chosenProductId,
          });
          await this.stripeAPICall('POST', '/subscribeFree', {
            productId: this.productInformation.id,
            userInformation: {
              name: `${this.user.attributes.given_name} ${this.user.attributes.family_name}`,
            },
          }).then((result) => {
            this.router.navigate(['/success'], {
              queryParams: { subId: result.subId },
            });
          });
        } else {
          this.router.navigateByUrl('/subscribe');
        }
      }
    } catch (error) {
      console.error('Error in priceSelectAction():', error);
    }
  }

  async stripeAPICall(
    method: 'GET' | 'POST' | 'PUT' | 'DELETE',
    path: string,
    params: { [key: string]: any } = {},
    getOrDeleteHeaders?: any,
    implicitStartWithSlash = true
  ): Promise<any> {
    const MAX_ATTEMPTS = 1;
    let response;
    // No need for explicit paths starting with /
    if (!path.startsWith('/') && implicitStartWithSlash) {
      path = `/${path}`;
    }
    for (
      let currentAttempts = 0;
      currentAttempts < MAX_ATTEMPTS;
      currentAttempts++
    ) {
      try {
        switch (method) {
          case 'GET':
            {
              const queryParams = this.stringifyParams(params);
              response = await API.get(
                STRIPE_API_NAME,
                path + queryParams,
                getOrDeleteHeaders ?? {}
              );
            }
            break;
          case 'POST':
            {
              response = await API.post(STRIPE_API_NAME, path, {
                body: {
                  ...params,
                },
              });
            }
            break;
          case 'PUT':
            {
              response = await API.put(STRIPE_API_NAME, path, {
                body: {
                  ...params,
                },
              });
            }
            break;
          case 'DELETE':
            {
              const queryParams = this.stringifyParams(params);
              response = await API.del(
                STRIPE_API_NAME,
                path + queryParams,
                getOrDeleteHeaders ?? {}
              );
            }
            break;
          default: {
            console.error('Unhandled API method:', method);
          }
        }
        return response;
      } catch (err) {
        throw err;
      }
    }
  }

  async getGuestPriceInformation() {
    const priceReq = (await this.stripeAPICall(
      'GET',
      'prices/guest'
    )) as GetGuestPriceInfoResult;
    if (!priceReq?.success) {
      throw priceReq.errorInfo;
    }
    this.priceInformation = priceReq.priceData;
    this.productInformation = priceReq.productData;
    // this.priceAmount =
    //   this.priceInformation.currency.toUpperCase() +
    //   '$' +
    //   this.toDollarString(
    //     this.priceInformation.unitAmount
    //   );
    this.isLive = priceReq.productData.isLive || priceReq.priceData.isLive;
  }

  async getDefaultPriceInformation() {
    const priceReq = (await this.stripeAPICall(
      'GET',
      `prices/default`
    )) as GetPriceInfoResult;
    if (!priceReq?.success) {
      throw priceReq.errorInfo;
    }
    this.priceInformation = priceReq.priceData;
    this.productInformation = priceReq.productData;
    this.isLive = priceReq.productData.isLive || priceReq.priceData.isLive;
  }

  async getProductInformation(priceId: string) {
    return await this.stripeAPICall('GET', `products/${priceId}`).then(
      (productInfo: {
        success: boolean;
        productData: StripeProductInfo;
        priceData: StripePriceInfo;
        isLive: boolean;
        error?: string;
      }) => {
        if (!productInfo?.success) {
          throw Error(productInfo.error);
        }
        this.isLive =
          productInfo.isLive ||
          productInfo.productData.isLive ||
          productInfo.priceData.isLive;
        return productInfo;
      }
    );
  }

  async getPriceOptionInformation(option: string | number) {
    const priceReq = (await this.stripeAPICall(
      'GET',
      `prices/options/${option}`
    )) as GetPriceInfoResult;
    if (!priceReq?.success) {
      throw priceReq.errorInfo;
    }
    this.priceInformation = priceReq.priceData;
    this.productInformation = priceReq.productData;
    // this.priceAmount =
    //   this.priceInformation.currency.toUpperCase() +
    //   '$' +
    //   this.toDollarString(this.priceInformation.unitAmount);
    this.isLive = priceReq.productData.isLive || priceReq.priceData.isLive;
  }

  async getCouponInfo(input: string) {
    if (!input?.length) {
      this.priceInformation.discount = this.priceInformation.couponUnitAmount =
        null;
      return;
    }
    await API.get(STRIPE_API_NAME, '/coupon', {
      queryStringParameters: {
        couponId: input,
        price: this.priceInformation.unitAmount,
      },
    })
      .then((result: GetCouponResult) => {
        if (result.success) {
          this.priceInformation.couponUnitAmount = result.newPrice;
          this.priceInformation.discount = result.discount;
        } else {
          this.priceInformation.couponUnitAmount = null;
          throw Error(result.error);
        }
      })
      .catch((error) => {
        console.error('Error performing GET /coupons API call:', error);
        throw error;
      });
  }

  /**
   * Call this when there is sufficient logic that the user has agreed
   * to the Casenoter terms via the consent page.
   */
  agreeToTerms() {
    this.agreed = true;
    localStorage.setItem('CASENOTER_AGREED', '1');
  }

  signOut() {
    return Auth.signOut();
  }

  private stringifyParams(params: any): string {
    return Object.keys(params ?? {}).reduce(
      (previousValue: string, currentValue: string) =>
        previousValue.length
          ? `${previousValue}&${currentValue}=${encodeURIComponent(
              params[currentValue]
            )}`
          : `?${currentValue}=${encodeURIComponent(params[currentValue])}`,
      ''
    );
  }
}
