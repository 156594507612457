import awsconfig from './aws-exports/aws-exports.dev';

export const environment = {
  production: false,
  awsconfig,
};

export const CASENOTER_DOWNLOAD_URL = 'https://download.casenoter.com/test/latest';
export const REGISTRATION_URL = 'https://dev.register.app.casenoter.com';
export const S3_BUCKET_ID = 'casenoterappv2-test-builds';
