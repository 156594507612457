/* eslint-disable @typescript-eslint/naming-convention */
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Auth } from 'aws-amplify';
import { AlertController, LoadingController } from '@ionic/angular';
import { AppService } from 'src/app/app.service';
import {
  CASENOTER_BUILD_WIN_X64,
  CASENOTER_BUILD_WIN_X86,
  CASENOTER_BUILD_MAC,
  DOWNLOAD_PAGE_URL,
} from '../app.constants';
import {
  environment,
  S3_BUCKET_ID,
  CASENOTER_DOWNLOAD_URL,
} from '../../environments/environment';

@Component({
  selector: 'app-success-page',
  templateUrl: './success.page.html',
  styleUrls: ['./success.page.scss'],
})
export class SuccessPage implements OnInit {
  @ViewChild('downloadRef') downloadButton: ElementRef;
  subscriptionData: any;
  expireTime: string;
  email: string;
  priceString: string;
  guest: boolean;
  version: string;

  get paymentIntegratedEmail() {
    return this.appService.paymentIntegratedEmail;
  }

  readonly s3BucketId = S3_BUCKET_ID;
  readonly buildWinX64 = CASENOTER_BUILD_WIN_X64;
  readonly buildWinX86 = CASENOTER_BUILD_WIN_X86;
  readonly buildMac = CASENOTER_BUILD_MAC;
  readonly isProduction = environment.production;
  readonly downloadPageUrl = DOWNLOAD_PAGE_URL;
  readonly directDownloadUrl = CASENOTER_DOWNLOAD_URL;

  constructor(
    private router: Router,
    private alertController: AlertController,
    private loadingController: LoadingController,
    private appService: AppService
  ) {
    this.version = this.appService.version;
  }

  async ngOnInit() {
    const loading = await this.loadingController.create({
      message: 'Getting session data...',
    });
    await loading.present();

    const subId =
      this.router.routerState.snapshot.root.queryParamMap.get('subId');
    this.guest =
      this.router.routerState.snapshot.root.queryParamMap.get('type') ===
      'guest';
    if (!this.guest) {
      const res = await this.getSubscriptionData(subId)
        .catch(async (e) => {
          const alert = await this.alertController.create({
            message: `The payment was processed successfully, but an error occurred while passing data to this page.
              You will be redirected to the CaseNoter download page. If you have issues signing in with the
              account you created, please do not hesitate to contact us by email at support@casenoter.com`,
            buttons: ['OK'],
            header: 'An error occurred',
          });
          await alert.present();
          alert.onDidDismiss().then(() => {
            document.getElementById('download-ref').click();
          });
        })
        .finally(async () => await loading.dismiss());
      if (!res?.success) {
        const alert = await this.alertController.create({
          message:
            'API returned an error. You are likely still able to log into your account. Contact support@casenoter.com for help.',
          buttons: ['OK'],
          header: 'An error occurred',
        });
        await alert.present();
        alert.onDidDismiss().then(() => {
          this.router.navigateByUrl('/manage');
        });
      }
      this.subscriptionData = res.subscriptionData;
      this.priceString =
        this.subscriptionData.plan.currency.toUpperCase() +
        '$' +
        this.appService.toDollarString(this.subscriptionData.plan.amount);
    }
    loading.message = 'Getting user data...';
    const user = await Auth.currentAuthenticatedUser();
    const attributes = await Auth.userAttributes(user);
    const date = new Date();
    this.expireTime = this.guest
      ? new Date(date.setMonth(date.getMonth() + 6)).toDateString()
      : new Date(
          this.subscriptionData.current_period_end * 1000
        ).toDateString();
    this.expireTime = this.expireTime
      .replace('Mon', 'Monday,')
      .replace('Tue', 'Tuesday,')
      .replace('Wed', 'Wednesday,')
      .replace('Thu', 'Thursday,')
      .replace('Fri', 'Friday,')
      .replace('Sat', 'Saturday,')
      .replace('Sun', 'Sunday,')
      .replace('Jan', 'January')
      .replace('Feb', 'February')
      .replace('Mar', 'March')
      .replace('Apr', 'April')
      .replace('Jun', 'June')
      .replace('Jul', 'July')
      .replace('Aug', 'August')
      .replace('Sep', 'September')
      .replace('Oct', 'October')
      .replace('Nov', 'November')
      .replace('Dec', 'December');
    this.email = attributes.find(
      (attribute) => attribute.Name === 'email'
    ).Value;
    loading.dismiss();
  }

  async getSubscriptionData(subId: string): Promise<any> {
    return await this.appService.stripeAPICall('GET', `subscriptions/${subId}`);
  }
}
