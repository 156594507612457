import { Component } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { AppService } from '../app.service';
import { Router } from '@angular/router';
import { Auth } from 'aws-amplify';

@Component({
  templateUrl: './consent.page.html',
  styleUrls: ['./consent.page.scss'],
})
export class ConsentPage {
  readonly guestEmail =
    this.router.routerState.snapshot.root.queryParamMap.get('guestEmail')?.trim().replace(/\s/g, '+') ?? '';
  readonly signInWith =
    this.router.routerState.snapshot.root.queryParamMap.get('signInWith')?.trim().replace(/\s/g, '+') ?? '';
  readonly paymentIntegratedEmail =
    this.router.routerState.snapshot.root.queryParamMap.get(
      'paymentIntegratedEmail'
    );
  readonly agreementTerms = [
    {
      agreed: false,
      text: `I accept that I have read and understood the CaseNoter Legal documentation on the
      CaseNoter Website including the CaseNoter App Terms of Service, the CaseNoter Policy
      Document, the Refund Policy, and the CaseNoter Website Terms and Conditions of Use,
      the End User Licence Agreement, and the Privacy Policy.`,
    },
    {
      agreed: false,
      text: `I understand that in agreeing to this Consent Form and registering as a CaseNoter
      User I have given Consent for the processing my personal date and information as set out
      in the Privacy Policy, or in the alternative, I understand that that my personal data and
      information is processed on a basis other than by my consent.`,
    },
    {
      agreed: false,
      text: `I understand that CaseNoter holds personal data and information about me and I
      hereby consent to the processing by CaseNoter or any associated company of my personal
      data and information for any purpose related to the performance of the CaseNoter End User
      Licence Agreement, the CaseNoter App Terms of Service, the CaseNoter Policy Document, the
      Refund Policy, and the CaseNoter Website Terms and Conditions of Use, including using my
      personal data and information for CaseNoter Company business.`,
    },
    {
      agreed: false,
      text: `I explicitly consent to CaseNoter or any associated company processing my personal
      data and information as necessary for the performance of the CaseNoter End User Licence
      Agreement, the CaseNoter App Terms of Service, the CaseNoter Policy Document, the Refund
      Policy, and the CaseNoter Website Terms and Conditions of Use, including using my personal
      data and information for CaseNoter Company business.`,
    },
  ];

  get allAgreed() {
    return this.agreementTerms.every((term) => term.agreed);
  }

  set allAgreed(agreed: boolean) {}

  constructor(
    private alertController: AlertController,
    private appService: AppService,
    private router: Router
  ) {
    const agreed = localStorage.getItem('CASENOTER_AGREED');
    if (agreed === '1') {
      this.appService.agreed = true;
      this.router.navigateByUrl('/home');
    }
    Auth.signOut();
    // if (this.paymentIntegratedEmail?.length) {
    //   this.appService.agreeToTerms();
    //   this.appService.paymentIntegratedEmail = this.paymentIntegratedEmail;
    //   const productOption = this.router.routerState.root.snapshot.queryParamMap.get('productOption');
    //   this.appService.priceOption = Number(productOption) || 1;
    //   this.router.navigate(['manage-in-app'], {
    //     queryParams: { paymentIntegratedEmail: this.paymentIntegratedEmail, productOption },
    //   });
    //   return;
    // }
    if (!this.appService.priceOption) {
      this.appService.priceOption = Number(
        this.router.routerState.snapshot.root.queryParamMap.get('priceOption')
      );
    }
    if (!this.appService.guestEmail) {
      this.appService.guestEmail = this.guestEmail;
    } else {
      this.router.navigateByUrl('/home');
    }
    if (!this.appService.signInWith) {
      this.appService.signInWith = this.signInWith;
      if (this.signInWith) {
        this.appService.agreeToTerms();
        this.router.navigateByUrl('/home');
      }
    } else {
      this.router.navigateByUrl('/home');
    }
  }

  async onAgree() {
    if (this.allAgreed) {
      this.appService.agreeToTerms();
      this.router.navigateByUrl('/home');
      return;
    }
    (
      await this.alertController.create({
        message: 'You must agree to all terms before proceeding.',
        buttons: ['OK'],
      })
    ).present();
  }

  agreeToAll(event: any) {
    const { allAgreed } = this;
    // Set all to false
    this.agreementTerms.forEach((term) => (term.agreed = !allAgreed));
  }
}
