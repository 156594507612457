import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { SubscribePage } from './subscribe/subscribe.page';
import { SuccessPage } from './success/success.page';
import { ManagePage } from './manage/manage.page';
import { ConsentPage } from './consent/consent.page';
import { ManageInAppPage } from './manage-in-app/manage-in-app.page';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./home/home.module').then((m) => m.HomePageModule),
  },
  {
    path: 'subscribe',
    component: SubscribePage,
  },
  {
    path: 'success',
    component: SuccessPage,
  },
  {
    path: 'manage',
    component: ManagePage,
  },
  // {
  //   path: 'consent',
  //   component: ConsentPage,
  // },
  {
    path: 'manage-in-app',
    component: ManageInAppPage,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
