export const getDisplayErrorMessage = (amplifyError: {
  code: string;
  name: string;
  message: string;
}) => {
  switch (amplifyError.code) {
    case 'UserNotFoundException': {
      return 'An account with this email does not exist.';
    }
    case 'NotConfirmedException':
    case 'UserNotConfirmedException': {
      return 'This email address was not confirmed.';
    }
    case 'UsernameExistsException': {
      return 'There is already a Casenoter account with this email address.';
    }
    case 'CodeMismatchException': {
      return 'The verification code is incorrect.';
    }
    case 'NetworkError': {
      return 'Please check the status of your Internet connection and try again.';
    }
    case 'NotAuthorizedException':
      {
        switch (true) {
          case /current status is confirmed/i.test(amplifyError.message):
            {
              return 'This email address is already confirmed.';
            }
          case /incorrect/i.test(amplifyError.message): {
            return amplifyError.message;
          }
            break;
          default: {
            return 'You cannot perform this operation with this account.';
          }
        }
      }
      break;
    case 'InvalidParameterException':
      {
        switch (true) {
          case /Username.email/i.test(amplifyError.message): {
            return 'Email address is not valid.';
          }
        }
      }
      break;
    default: {
      // e.g. "Request failed with status code 500"
      return /request failed with/i.test(amplifyError.message)
        ? 'Sorry, something went wrong. Please try again later.'
        : amplifyError.message;
    }
  }
};
